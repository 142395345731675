<template>
  <v-tab-item>
    <StandsList :stands="allStands"/>
  </v-tab-item>
</template>

<script>
import { mapState } from 'vuex'
import StandsList from '../StandsList'

export default {
  name: 'allStands',
  components: {
    StandsList
  },
  computed: {
    ...mapState({
      onlineStands: state => state.stand.standData.stands,
      offlineStands: state => state.stand.standDataOffline
    }),
    allStands () {
      return [...this.onlineStands, ...this.offlineStands]
    }
  }
}
</script>