<template lang="pug">
  .modal
    .modal-overlay(@click="closeSettings()")
    .modal-content
      .modal-content__close
        i.icon-close(@click="closeSettings()")
      CalibrationWeight(
        v-if="type === 'CalibrationWeight'"
        :item="item"
      )
      CalibrationBrakeForce(
        v-if="type === 'CalibrationBrakeForce'"
        :item="item"
      )
      CalibrationPedalSensor(
        v-if="type === 'CalibrationPedalSensor'"
        :item="item"
      )
      CalibrationHandbrakeSensor(
        v-if="type === 'CalibrationHandbrakeSensor'"
        :item="item"
      )
      SettingsSensor(
        v-if="type === 'SettingsSensor'"
        :item="item"
      )
      SettingsCharacteristics(
        v-if="type === 'SettingsCharacteristics'"
        :item="item"
      )
      SettingsReboot(
        v-if="type === 'SettingsReboot'"
        :item="item"
      )
      SettingsCheckConnect(
        v-if="type === 'SettingsCheckConnect'"
        :item="item"
      )
      SettingsEdit(
        v-if="type === 'SettingsInfo'"
        :item="item"
      )
      SettingsDeparture(
        v-if="type === 'SettingsDeparture'"
        :item="item"
      )
</template>

<script>
import CalibrationWeight from './SettingsCalibration/Weight.vue'
import CalibrationBrakeForce from './SettingsCalibration/BrakeForce.vue'
import CalibrationPedalSensor from './SettingsCalibration/PedalSensor.vue'
import CalibrationHandbrakeSensor from './SettingsCalibration/HandbrakeSensor.vue'

import SettingsSensor from './SettingsSensor.vue'
import SettingsCharacteristics from './SettingsCharacteristics.vue'
import SettingsReboot from './SettingsReboot.vue'
import SettingsEdit from './SettingsEdit.vue'
import SettingsCheckConnect from './SettingsCheckConnect.vue'

import SettingsDeparture from '@/views/Stands/StandSettings/SettingsDeparture'

export default {
  name: 'standSettings',
  props: ['type','item'],
  components: {
    SettingsDeparture,
    CalibrationWeight,
    CalibrationBrakeForce,
    CalibrationPedalSensor,
    CalibrationHandbrakeSensor,
    SettingsSensor,
    SettingsCharacteristics,
    SettingsReboot,
    SettingsCheckConnect,
    SettingsEdit
  },
  data() {
    return {
      CalibrationWeight: false,
      CalibrationBrakeForce: false,
      CalibrationPedalSensor: false,
      CalibrationHandbrakeSensor: false,
      SettingsSensor: false,
      settingsReboot: false
    }
  },
  computed: {

  },
  methods: {
    closeSettings() {
      this.$parent.settings.status = false
    }
  },
  mounted() {
    console.log(this.item);
  }
}
</script>
