<template lang="pug">
  include ../../../assets/pug/index
  div
    .modal-content__spinner(v-if="pending")
      i.icon-spinner
    .modal-content__header {{$t('standSettings.standEdits')}}
    .modal-content__body
      .mt-2
        +input('item.id', 'uniqueIdentifier')(disabled hide-details="auto")
      .mt-8
        +inputValidation('item.name', 'nameStand', '["required"]')(hide-details="auto")
      .body--sensor__actions.mt-8
        +btn('primary', 'btn.save')(
          :loading="isLoad"
          color="primary"
          elevation="2"
          @click="validation()")
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { api } from '@/mixins/api'

export default {
  name: 'standSettingsCharacteristics',
  props: {
    item: Object
  },
  data () {
    return {
      isLoad: false,
      validationField
    }
  },
  validations: validate.editStandInfo,
  computed: {
    checkName () {
      return this.$validate.required(this.$v.item.name)
    },
  },
  methods: {
    validation() {
      this.$v.$touch()
      !this.$v.$invalid && this.setStandInfo()
    },
    setStandInfo() {
      let body = JSON.stringify({
        stand_name: this.item.name,
        stand: this.item.id,
      })
      api.put('admin/stand-info', body)
        .then(response => {
          console.log(response)
          this.$notification.success(this.$i18n.t('notify.changedUser'))
        })
    }
  },
  mounted() {
  }
}

</script>
