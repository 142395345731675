<template lang="pug">
  div
    .modal-content__progress(v-if="pending.isStatus")
      //- i.icon-spinner
      .progress--title Зачейте будь ласка.
      div(style="width: 80%; padding: 20px")
        .progressBar
          .departure
        .progress--percent Прогрес: {{ pending.percent }}% / 100%
    .modal-content__header Виїзд вантажного транспорту
    .modal-content__body(v-if="!isDone && !isBusy")
      .body--title
        span {{$t('address')}}
        span {{ item.address }} 
        <br>
        span {{$t('nameStand')}}:
        span {{ item.name }}
    .modal-content__body(v-if="isDone")
      .body--done
        i.icon-done
        span Виїзд зі стенду пройшов успішно
    .modal-content__body(v-if="isBusy")
      .body--done
        i.icon-error
        span Стенд виконує іншу задачу
        span Спробуйте будь ласка пізніше!
    .modal-content__actions(v-if="!isDone && !isBusy")
      .actions--step
        //- span Крок 2 з 2
      .actions--btn
        ul
          li
            button.btn(@click="standDeparture()") Почати
    .modal-content__actions(v-if="isBusy")
      .actions--step
        //- span Крок 2 з 2
      .actions--btn
        ul
          li
            button.btn(@click="isBusy = !isBusy") Назад
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'standSettingsReboot',
  props: {
    item: Object
  },
  data () {
    return {
      pending: {
        isStatus: false,
        percent: 0,
        isDone: false
      },
      isDone: false,
      isBusy: false
    }
  },
  computed: {
    ...mapState({
      user_id: state => state.stand.standData.user_id
    })
  },
  methods: {
    standDeparture () {
      this.pending.isStatus = true
      this.$api.get(`action/${this.item.id}/drive_out/`)
      let timerId = setInterval(() => {
        this.pending.percent ++
      }, 112);
      setTimeout(() => {
        clearInterval(timerId)
        this.pending.isStatus = false
        this.isDone = true
      }, 10000);
    }
  }
}


</script>
