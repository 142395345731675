<template>
  <div>
    <div>
      <v-tabs v-model="tabs">
        <v-tab> <span class="stand-status online mr-2"></span>{{$t('online')}}</v-tab>
        <v-tab>{{$t('all')}}</v-tab>
        <v-tab>{{$t('new')}}</v-tab>
        <v-tab> <span class="stand-status offline mr-2"></span>{{$t('offline')}}</v-tab>

      </v-tabs>

      <v-tabs-items v-model="tabs">
        <OnlineStands />
        <AllStands />
        <NewStands />
        <OfflineStands />
      </v-tabs-items>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import AllStands from './StandTabItems/AllStands.vue'
import NewStands from './StandTabItems/NewStands.vue'
import OnlineStands from './StandTabItems/OnlineStands.vue'
import OfflineStands from './StandTabItems/OfflineStands.vue'


export default {
  name: 'standList',
  components: {
    AllStands,
    NewStands,
    OnlineStands,
    OfflineStands
  },
  data() {
    return {
      title: 'Стенди',
      select: false,
      tabs: null
    }
  },
  computed: {
    ...mapState({
      stands: state => state.stand.standData.stands,
    })
  },
  mounted()  {
    this.$store.commit('setDataTitle', this.$i18n.t('stands'))
  }
}

</script>
