<template>
  <v-tab-item>
  </v-tab-item>
</template>

<script>

export default {
  name: 'newStands',
}
</script>