<template>
  <div>
    <Table
      :headers="headers"
      :items="stands"
      :items-per-page="10"
      :openSettings="openSettings"
      :standActivity="standActivity"
      :userID="userid"
      :offline="offline"
      />
    <div class="">
      <StandSettings
        v-if="settings.status"
        :type="settings.type"
        :item="settings.item"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StandSettings from './StandSettings/StandSettings'

export default {
  name: 'standList',
  components: {
    StandSettings
  },
  props: {
    stands: Array,
    offline: Boolean
  },
  data () {
    return {
      settings: {
        status: false,
        type: null,
        item: null
      },
      headers: [
        { text: 'IP', value: 'last_ip'},
        { text: this.$i18n.t('numberStand'), value: 'id'},
        { text: this.$i18n.t('version'), value: 'sw_version'},
        { text: this.$i18n.t('address'), value: 'address'},
        { text: this.$i18n.t('nameStand'), value: 'name'},
        { text: this.$i18n.t('user'), value: 'occupied_by.full_name' },
        { text: this.$i18n.t('lastActivity'), value: 'updated_at' },
        { value: 'actionStands' }
      ],
      items: [],
      tableLoader: false,
    }
  },
  computed: {
    ...mapState({
      // stands: state => state.stand.standData.stands,
      userid: state => state.stand.standData.user_id,
      permissions: state => state.permissions.permissions
    })
  },
  methods: {
    ...mapActions(['setStandActivity']),
    openSettings(item, type) {
      this.settings.status = true
      this.settings.type = type
      this.settings.item = item
    },
    standActivity (type, title, id) {
      this.$swal({
        title: this.$i18n.t(title),
        icon: 'info',
        buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
      })
      .then(standActivity => {
        let body = {
          type: type,
          id: id
        }
        standActivity && this.setStandActivity(body)
      })
    }
  },
}

</script>
