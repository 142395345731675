<template lang="pug">
  div
    .modal-content__progress(v-if="pending.isStatus")
      //- <!-- <i class="icon-spinner"></i> -->
      .progress--title Зачейте будь ласка, стенд перезавантажується!
      div(style="width: 80%; padding: 20px")
        .progressBar
          span
        .progress--percent {{$t('progress')}}: {{ pending.percent }}% / 100%
    .modal-content__header {{$t('standSettings.standReboot')}}
    .modal-content__body(v-if="!isDone && !isBusy")
      .body--title
        span {{$t('address')}}: {{ item.address }}
        br
        span {{$t('nameStand')}}: {{ item.name }}
    .modal-content__body(v-if="isDone")
      .body--done
        i.icon-done
        span  {{$t('standSettings.standRebootSuccess')}}
    .modal-content__body(v-if="isBusy")
      .body--done
        i.icon-error
        span {{$t('standSettings.standRebootNotPossible')}}
        span {{$t('tryAgainLater')}}
    .modal-content__actions(v-if="!isDone && !isBusy")
      .actions--step
        //- <!-- <span>Крок 2 з 2</span> -->
      .actions--btn
        ul
          li
            button.btn(@click="standReboot()") {{$t('restart')}}
    .modal-content__actions(v-if="isBusy")
      .actions--step
        //- <!-- <span>Крок 2 з 2</span> -->
      .actions--btn
        ul
          li
            button.btn(@click="isBusy = !isBusy") {{$t('btn.back')}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'standSettingsReboot',
  props: {
    item: Object
  },
  data () {
    return {
      pending: {
        isStatus: false,
        percent: 0,
        isDone: false
      },
      isDone: false,
      isBusy: false
    }
  },
  computed: {
    ...mapState({
      user_id: state => state.stand.standData.user_id
    })
  },
  methods: {
    standReboot () {
      this.$api.get(`action/${this.item.id}/abort`)
      this.pending.isStatus = true
      let timerId = setInterval(() => {
        this.pending.percent = this.pending.percent + 1
      }, 820);
      setTimeout(() => {
        clearInterval(timerId)
        this.pending.isStatus = false
        this.isDone = true
      }, 82000);
    }
  }
}


</script>
