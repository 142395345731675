<template lang="pug">
  div
    .modal-content__spinner(v-if="status.isWait")
      i.icon-spinner
    .modal-content__header {{$t('standSettings.calibrationBrakeForce')}}
    .modal-content__body(v-if="!status.isDone")
      .body--title
        span {{$t('address')}}
        span {{ item.address }}
        <br>
        span {{$t('nameStand')}}:
        span {{ item.name }}
      p {{$t('loadBrakingForseAndSet')}}
      v-select.w-70(
        v-model="side.model"
        :items="side.items"
        item-text="name"
        :label="$t('selectSideLoad')"
        persistent-hint
        return-object
        single-line
      )
      v-text-field.w-70(
        v-model="amount"
        :label="$t('effort')"
        hide-details="auto"
        type="text"
        onkeypress="return /[0-9.,]/i.test(event.key)"
        :suffix="$t('unitN')"
      )
    .modal-content__body(v-if="status.isDone")
      .body--done
        i.icon-done
        span {{$t('standSettings.calibrationEffortSuccess')}}
    .modal-content__actions
      .actions--step
        //- span Крок 2 з 2
      .actions--btn
        ul
          //- li
          //-   button.btn.btn--grey Назад
          li(v-if="!status.isDone")
            button.btn(@click="calibrationBrakeForce()") {{$t('setWeight')}}
</template>

<script>
export default {
  name: 'calibrationBrakeForce',
  props: {
    item: Object
  },
  data() {
    return {
      side: {
        items: [{
            id: 1,
            name: 'Ліва'
          },
          {
            id: 2,
            name: 'Права'
          }
        ],
        model: null
      },
      amount: null,
      status: {
        isDone: false,
        isWait: false
      }
    }
  },
  methods: {
    fetchBrakeForse (body) {
      this.$api.post('action/calibrate/brake', body)
        .then(response => {
          console.log(response)
          this.status.isDone = true
          this.status.isWait = false
        })
        .catch(err => {
          console.log(err);
        })
    },
    calibrationBrakeForce() {
      this.status.isWait = true
      let validAmount = this.amount.replace(/,/, '.') * 1
      let body = JSON.stringify({
        stand_serial: this.item.id,
        platform: this.side.model.id,
        referent_weight: validAmount,
        user_id: 0
      })
      this.fetchBrakeForse(body)
    }
  },
}
</script>
