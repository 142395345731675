<template lang="pug">
  div
    .modal-content__header {{ $t('title.testingStandSensor') }}
    .modal-content__body
      .body--title
        span {{ $t('address') }}:
        span {{ item.address }}
        br
        span {{ $t('nameStand') }}:
        span {{ item.name }}
      ul
        li(
          v-if="!sensor"
          @click="managementCheckBox(false)"
          ) {{ $t('btn.uncheckAll') }}
        li(
          v-if="!sensor"
          @click="managementCheckBox(true)"
          ) {{ $t('btn.chackAll') }}
        li(
          v-if="sensor"
          @click="sensor = false"
          ) {{ $t('btn.back') }}
      .body--sensor
        .body--sensor__type(v-if="!sensor")
            v-checkbox(
              v-for="(check, index) in type"
              v-model="check.checked"
              :key="index"
              :label="check.label"
            )
        .body--sensor__list(v-if="sensor")
          div(
            v-for="(check, index) in type"
            :key="index"
            :class="{'body--sensor__list-danger': check.slug === 'sensorDrive'}"
          )
              div(v-if="check.checked")
                .body--sensor__title  {{ check.label }}
                .body--sensor__message(:class="check.status")
                  span {{ check.msg }}
                  i.icon-spinner
                .d-flex.justify-end.mb-3(v-if="check.value")
                  span {{ $t('result') }}:
                  b.mx-2 {{ check.value.toFixed(2) }}
                  span {{ check.typeValue }}
                .body--sensor__actions
                  v-btn.btn(
                    @click="testSensor(item, check.type, check.side, index)"
                    :disabled="testing"
                  ) {{ $t('btn.startTest') }}
    .modal-content__actions(v-if="!sensor")
      .actions--step
        span {{ $t('step') }} {{ !sensor ? '1' : '2' }} {{ $t('of') }} 2
      .actions--btn
        v-btn.btn(@click="showSensor()") {{ $t('btn.next') }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'settingsSensor',
  props: {
    item: Object
  },
  data () {
    return {
      sensor: false,
      testing: false,
      type: [
        {
          type: '0',
          side: '1',
          status: 'ready',
          checked: true,
          label: this.$i18n.t('standSensor.weight'),
          typeValue: this.$i18n.t('unitWeight'),
          value: '',
          slug: 'sensorWeight'
        },
        {
          type: '0',
          side: '2',
          label: this.$i18n.t('standSensor.sensorPedal'),
          slug: 'sensorPedal'
        },
        {
          type: '0',
          side: '3',
          label: this.$i18n.t('standSensor.sensorHandbrake'),
          slug: 'sensorHandbrake'
        },
        {
          type: '5',
          side: '1',
          label: this.$i18n.t('standSensor.sensorDrive'),
          slug: 'sensorDrive'
        },
        {
          type: '1',
          side: '1',
          label: this.$i18n.t('standSensor.effortLeft'),
          typeValue: this.$i18n.t('unitKN'),
          value: '',
          slug: 'sensorEffor'
        },
        {
          type: '1',
          side: '2',
          label: this.$i18n.t('standSensor.effortRight'),
          typeValue: this.$i18n.t('unitKN'),
          value: '',
          slug: 'sensorEffor'

        },
        {
          type: '3',
          side: '1',
          label: this.$i18n.t('standSensor.speedSensorLeft'),
          slug: 'speedSensor'

        },
        {
          type: '3',
          side: '2',
          label: this.$i18n.t('standSensor.speedSensorRight'),
          slug: 'speedSensor'

        },
        {
          type: '4',
          side: '1',
          label: this.$i18n.t('standSensor.performanceIndicator'),
        },
        {
          type: '4',
          side: '2',
          label: this.$i18n.t('standSensor.readyIndicator'),
        },
        {
          type: '2',
          side: '1',
          label: this.$i18n.t('standSensor.presenceSensorLeft'),
          slug: 'presenceSensor'

        },
        {
          type: '2',
          side: '2',
          label: this.$i18n.t('standSensor.presenceSensorRight'),
          slug: 'presenceSensor'

        },
      ]
    }
  },
  beforeMount () {
    this.managementCheckBox (true)
  },
  methods: {
    ...mapActions(['runSensorTest']),
    showSensor () {
      this.sensor = true
      this.type.forEach(e => {
        e.status = 'ready'
        this.completeMsg(e)
      });
    },
    completeMsg (item) {
      switch (item.slug) {
        case 'sensorWeight': item.msg = this.$i18n.t('title.startScales')
          break;
        case 'sensorDrive': item.msg = this.$i18n.t('title.warningSensor')
          break;
        case 'sensorEffor': item.msg = this.$i18n.t('title.startBrake')
          break;
        case 'speedSensor': item.msg = this.$i18n.t('title.getSpeedShaft')
          break;
        case 'presenceSensor': item.msg = this.$i18n.t('status.startAndWait') + '.' + this.$i18n.t('title.turnSpeedShaft')
          break;
        default : item.msg = this.$i18n.t('status.startAndWait')
          break;
        }
    },
    managementCheckBox (type) {
      this.type.forEach(e => {
        this.$set(e, 'checked', type)
      });
    },
    testSensor (item, type, side, index) {
      if (type === '5') { this.$swal({
        title: this.$i18n.t('title.warningSensor'),
        icon: 'info',
        buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.startTest')]
      })
        .then(approve => {
          if (approve) {
            this.type[index].status = 'testing'
            this.testing = true
            let body = {
              id: item.id,
              side: side,
              type: type
            }
            this.runSensorTest(body)
              .then(response => {
                if (response.result) {
                  this.testing = false
                  this.type[index].value = response.value
                  this.type[index].status = 'approve'
                  this.type[index].msg = this.$i18n.t('status.testingPassed')
                } else {
                    this.type[index].status = 'error'
                    this.type[index].msg = this.$i18n.t('status.testingFailed')
                    this.testing = false
                }
              })
          }
        })} else {
          this.type[index].status = 'testing'
          this.testing = true
          let body = {
            id: item.id,
            side: side,
            type: type
          }
          this.runSensorTest(body)
              .then(response => {
                if (response.result) {
                  this.testing = false
                  this.type[index].value = response.value
                  this.type[index].status = 'approve'
                  this.type[index].msg = this.$i18n.t('status.testingPassed')
                } else {
                  this.type[index].status = 'error'
                  this.type[index].msg = this.$i18n.t('status.testingFailed')
                  this.testing = false
                }
              })
      }

    },
  }
}
</script>
