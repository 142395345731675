<template lang="pug">
  div
    .modal-content__spinner(v-if="status.isWait")
      i.icon-spinner
    .modal-content__header {{$t('standSettings.calibrationHandbrakeSensor')}}
    .modal-content__body(v-if="!status.isDone")
      .body--title
        span {{$t('address')}}
        span {{ item.address }}
        <br>
        span {{$t('nameStand')}}:
        span {{ item.name }}
      v-text-field.w-70.mt-8(
        v-model="amount"
        :label="$t('effort')"
        hide-details="auto"
        type="text"
        onkeypress="return /[0-9.,]/i.test(event.key)"
        :suffix="$t('unitN')"
      )
    .modal-content__body(v-if="status.isDone")
      .body--done
        i.icon-done
        span {{$t('standSettings.calibrationHandbrakeSuccess')}}
    .modal-content__actions
      .actions--step
      .actions--btn
        ul
          li(v-if="!status.isDone")
            button.btn(@click="calibrationPedalSensor()") {{$t('btn.start')}}
</template>

<script>
export default {
  name: 'calibrationBrakeForce',
  props: {
    item: Object
  },
  data() {
    return {
      amount: null,
      status: {
        isDone: false,
        isWait: false
      }
    }
  },
  computed: {
  },
  methods: {
    calibrationPedalSensor() {
      this.status.isWait = true
      let validAmount = this.amount.replace(/,/, '.') * 1
      let body = JSON.stringify({
        stand_serial: this.item.id,
        platform: 4,
        referent_weight: validAmount,
        user_id: 0
      })
      this.$api.post('action/calibrate/brake', body)
        .then(response => {
          console.log(response);
          this.status.isDone = true
          this.status.isWait = false
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}
</script>
