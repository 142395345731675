<template>
  <v-tab-item>
    <StandsList :stands="stands"/>
  </v-tab-item>
</template>

<script>
import { mapState } from 'vuex'
import StandsList from '../StandsList'

export default {
  name: 'onlineStands',
  components: {
    StandsList
  },
  computed: {
    ...mapState({
      stands: state => state.stand.standData.stands
    })
  }
}
</script>