<template>
  <v-tab-item>
    <StandsList :stands="stands" offline/>
  </v-tab-item>
</template>

<script>
import { mapState } from 'vuex'
import StandsList from '../StandsList'

export default {
  name: 'offlineStands',
  components: {
    StandsList
  },
  computed: {
    ...mapState({
      stands: state => state.stand.standDataOffline
    })
  }
}
</script>