<template lang="pug">
  div
    .modal-content__header {{$t('standSettings.checkConnectStand')}}
    .modal-content__body(v-if="!done")
      .body--title
        span {{$t('address')}}: {{ item.address }}
        br
        span {{$t('nameStand')}}: {{ item.name }}
    .modal-content__body(v-else)
      .body--done
        i.icon-done
        span {{$t('standSettings.standCheckSuccess')}}
        p
          span.mr-1 {{$t('stand')}}
          span(v-if="status === 'offline'") {{$t('offline')}}
          span(v-if="status === 'online'") {{$t('online')}}
    .modal-content__actions(v-if="!done")
      .actions--step
      .actions--btn
        ul
          li
            button.btn(@click="standCheck()") {{$t('check')}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'standSettingsReboot',
  props: ['item'],
  data () {
    return {
      status: '',
      done: false,
      busy: false
    }
  },
  computed: {
    ...mapState({
      user_id: state => state.stand.standData.user_id
    })
  },
  methods: {
    standCheck () {
      const params = new URLSearchParams()
      params.set('stand_serial', this.item.id)
      this.$api.get(`stand/offline/check?${params}`)
        .then((responce) => {
          this.done = true
           this.status = responce.data.result ? 'online' : 'offline'
        })
    }
  }
}


</script>
