<template lang="pug">
  include ../../../assets/pug/index
  div
    .modal-content__spinner(v-if="pending")
      i.icon-spinner
    .modal-content__header {{$t('standSettings.settingsCharacteristics')}}
    .modal-content__body(v-if="!done")
      .body--title
        span {{$t('address')}}: {{ item.address }}
        br
        span {{$t('nameStand')}}: {{ item.name }}
      .body--characteristics
          .w-100
            +input("consts.speed_limit", 'inputLabel.minSpeed')(:suffix="$t('unitKmYear')")
          div
            +input("consts.left_shaft_diameter", 'inputLabel.diametrLeft')(:suffix="$t('unitM')")
            +input("consts.right_shaft_diameter", 'inputLabel.diametrRight')(:suffix="$t('unitM')")
          div
            +input("consts.left_pulse_per_round", 'inputLabel.holesLeft')(:suffix="$t('unitPcs')")
            +input("consts.right_pulse_per_round", 'inputLabel.holesRight')(:suffix="$t('unitPcs')")
          div
            +input("consts.arm_force_factor", 'inputLabel.armForceFactor')
          div
            +input("consts.pedal_force_factor", 'inputLabel.pedalForceFactor')
          div
            +input("consts.min_weight_value", 'inputLabel.minWeight')(:suffix="$t('unitWeight')")
          div
            +input("consts.max_weight_value", 'inputLabel.maxWeight')(:suffix="$t('unitWeight')")
          div
            +input("consts.left_brake_factor", 'inputLabel.brakingForceLeft')
          div
            +input("consts.right_brake_factor", 'inputLabel.brakingForceRight')
          .w-100
            +input("consts.total1_weight_factor", 'inputLabel.coefficientWeight')
          .body--characteristics__radio
            span {{$t('inputLabel.rotationSensorLeft')}}
            v-radio-group(v-model="consts.left_speed_sensor_type")
              v-radio(label="NC" :value="1")
              v-radio(label="NO" :value="2")
          .body--characteristics__radio
            span {{$t('inputLabel.rotationSensorRight')}}
            v-radio-group(v-model="consts.right_speed_sensor_type")
              v-radio(label="NC" :value="1")
              v-radio(label="NO" :value="2")
          .body--characteristics__radio
            span {{$t('inputLabel.availabilitySensorLeft')}}
            v-radio-group(v-model="consts.left_presence_sensor_type")
              v-radio(label="NC" :value="1")
              v-radio(label="NO" :value="2")
          .body--characteristics__radio
            span {{$t('inputLabel.availabilitySensorRight')}}
            v-radio-group(v-model="consts.right_presence_sensor_type")
              v-radio(label="NC" :value="1")
              v-radio(label="NO" :value="2")
    .modal-content__body(v-if="done")
      .body--done
        i.icon-done
        span {{$t('standSettings.standSettingsSuccess')}}
    .modal-content__actions(v-if="!done")
      .actions--step
        //- <!-- <span>Крок 2 з 2</span> -->
      .actions--btn
        ul
          //- <!-- <li><button class="btn btn--grey">Назад</button></li> -->
          li
            button.btn(@click="saveConst()") {{$t('save')}}
</template>

<script>
import { api } from '@/mixins/api'
import { mapState } from 'vuex'

export default {
  name: 'standSettingsCharacteristics',
  props: ['item'],
  data () {
    return {
      radioGroup: 1,
      pending: false,
      done: false,
      consts: {
        left_brake_factor: null,
        left_pulse_per_round: null,
        left_shaft_diameter: null,
        max_weight_value: null,
        min_weight_value: null,
        right_brake_factor: null,
        right_shaft_diameter: null,
        speed_limit: null,
        total1_weight_factor: null,
        left_presence_sensor_type: String,
        left_speed_sensor_type: null,
        right_speed_sensor_type: null,
        right_presence_sensor_type: null,
        right_pulse_per_round: null,
        total2_weight_factor: null,
        arm_force_factor: null,
        pedal_force_factor: null,
      }
    }
  },
  computed: {
    ...mapState({
      user_id: state => state.stand.standData.user_id
    })
  },
  methods: {
    async getConst() {
      console.log('get');
      await api.get('stand/consts?stand_serial=' + this.item.id)
      .then(response => {
        this.consts.left_brake_factor = response.data.left_brake_factor
        this.consts.left_pulse_per_round = response.data.left_pulse_per_round
        this.consts.left_shaft_diameter = response.data.left_shaft_diameter.toFixed(4)
        this.consts.max_weight_value = response.data.max_weight_value.toFixed(0)
        this.consts.min_weight_value = response.data.min_weight_value.toFixed(0)
        this.consts.right_brake_factor = response.data.right_brake_factor
        this.consts.right_shaft_diameter = response.data.right_shaft_diameter.toFixed(4)
        this.consts.speed_limit = response.data.speed_limit.toFixed(1)
        this.consts.total1_weight_factor = response.data.total1_weight_factor.toFixed(8)
        this.consts.left_presence_sensor_type = response.data.left_presence_sensor_type
        this.consts.left_speed_sensor_type = response.data.left_speed_sensor_type
        this.consts.right_speed_sensor_type = response.data.right_speed_sensor_type
        this.consts.right_presence_sensor_type = response.data.right_presence_sensor_type
        this.consts.right_pulse_per_round = response.data.right_pulse_per_round
        this.consts.arm_force_factor = response.data.arm_force_factor
        this.consts.pedal_force_factor = response.data.pedal_force_factor
        this.consts.total2_weight_factor = response.data.total2_weight_factor.toFixed(4)
      })
    },
    saveConst() {
      this.pending = true
      let body = JSON.stringify({
        stand_serial: this.item.id,
        weight_platforms_num: 1,
        left_shaft_diameter: Number(this.consts.left_shaft_diameter),
        right_shaft_diameter: Number(this.consts.right_shaft_diameter),
        left_pulse_per_round: Number(this.consts.left_pulse_per_round),
        right_pulse_per_round: Number(this.consts.right_pulse_per_round),
        speed_limit: Number(this.consts.speed_limit),
        min_weight_value: Number(this.consts.min_weight_value),
        left_brake_factor: Number(this.consts.left_brake_factor),
        right_brake_factor: Number(this.consts.right_brake_factor),
        total1_weight_factor: Number(this.consts.total1_weight_factor),
        total2_weight_factor: Number(this.consts.total2_weight_factor),
        user_id: Number(this.user_id),
        max_weight_value: Number(this.consts.max_weight_value),
        left_speed_sensor_type: Number(this.consts.left_speed_sensor_type),
        left_presence_sensor_type: Number(this.consts.left_presence_sensor_type),
        right_speed_sensor_type: Number(this.consts.right_speed_sensor_type),
        right_presence_sensor_type: Number(this.consts.right_presence_sensor_type),
        arm_force_factor: Number(this.consts.arm_force_factor),
        pedal_force_factor: Number(this.consts.pedal_force_factor)
      })
      let headers
      api.post('stand/consts/', body, headers)
        .then(response => {
          switch(response.data.msg) {
            case 'Constants are set':
              this.pending = false
              this.done = true
          }
        })
    }
  },
  mounted() {
    this.getConst()
  }
}

</script>
